import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { MemeInputText } from '~/components/common/forms';

import { Button } from './buttons';

import {
  convertKBtoMB,
  isFileTypeAnimatedImage,
  isFileTypeImage,
  isFileTypeVideo,
  isSupportedFileType,
} from '~/utils/files';

import { square, squareShort } from '~/styles/mixins';
import { APP } from '~/constants';

const StyledContainer = styled('div')`
  .image-container {
    ${squareShort};

    canvas {
      border-radius: var(--radius-default);
      display: none;
    }

    .canvas-thumbnail {
      display: none;
    }
  }

  .add-text-button {
    background-color: var(--periwinkle);
    display: inline-block;
    padding: 5px 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    color: var(--purple);
  }

  .input-container {
    ${square};

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    border-radius: var(--radius-default);
    // todo: use variables for 'rx='8' ry='8' (--radius-default) stroke='%23F8DDFF' ('F8DDFF' is --lavendar)'
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23F8DDFF' stroke-width='3' stroke-dasharray='10 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    cursor: pointer;
  }

  .extra-text {
    text-align: center;
  }

  .advice {
    color: var(--periwinkle);
  }
`;

function DraggableText({ x, y, text, textStyle, imageRef, fontSize, onDragStop, onTouchStart, onTouchEnd }) {
  const [position, setPosition] = React.useState({ x, y });
  const [isDragging, setIsDragging] = React.useState(false);
  const [mouseOffset, setMouseOffset] = React.useState({ x: 0, y: 0 });

  const textRef = React.useRef();
  const [scale, setScale] = React.useState({ x: 1, y: 1 });

  const calcScale = React.useCallback(() => {
    if (!textRef.current) return;
    const parent = textRef.current.parentElement;
    // const viewbox = imageRef.getBoundingClientRect();// parent.viewBox.baseVal;
    // const rect = imageRef.getBoundingClientRect();
    // console.log("viewbox", viewbox)
    const scale = {
      x: 1, // viewbox.width / Math.round(rect.width),
      y: 1, // viewbox.height / Math.round(rect.height)
    };
    // console.log("scale", scale)
    return scale;
  }, []);

  const handleMouseDown = React.useCallback(
    (event) => {
      event.preventDefault();
      setIsDragging(true);

      const scale = calcScale();
      setScale(scale);
      /*
    const newPosition = {
      x: (event.clientX * scale.x) - position.x,
      y: (event.clientY * scale.y) - position.y,
    }
    setMouseOffset(newPosition) */
    },
    [position, calcScale, scale]
  );

  const handleMouseUp = React.useCallback(() => {
    if (!isDragging) return;

    if (onDragStop) {
      onDragStop();
    }

    setIsDragging(false);
  }, [isDragging]);

  const handleMouseMove = React.useCallback(
    (event) => {
      if (!isDragging) return;

      /*
    setPosition({
      x: scale.x * event.clientX - mouseOffset.x,
      y: scale.y * event.clientY - mouseOffset.y,
    }) */

      const rect = imageRef.getBoundingClientRect();
      setPosition({
        /* x: `${(event.clientX - rect.left) / rect.width * 100}%`,
      y: `${(event.clientY - rect.top) / rect.height * 100}%`, */
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      });
    },
    [isDragging, mouseOffset, scale]
  );

  const handleTouchStart = React.useCallback(
    (event) => {
      event.preventDefault();
      setIsDragging(true);

      const scale = calcScale();
      setScale(scale);
      onTouchStart();
    },
    [position, calcScale, scale]
  );

  const handleTouchEnd = React.useCallback(() => {
    if (!isDragging) return;
    if (onDragStop) {
      onDragStop();
    }
    onTouchEnd();
    setIsDragging(false);
  }, [isDragging]);

  const handleTouchMove = React.useCallback(
    (event) => {
      if (!isDragging) return;
      const touch = event.touches[0];

      const rect = imageRef.getBoundingClientRect();
      setPosition({
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top,
      });
    },
    [isDragging, mouseOffset, scale]
  );

  // External listeners
  React.useEffect(() => {
    if (!isDragging) return;
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  React.useEffect(() => {
    if (!isDragging) return;
    textRef.current.addEventListener('touchmove', handleTouchMove);
    textRef.current.addEventListener('touchend', handleTouchEnd);
    textRef.current.addEventListener('touchcancel', handleTouchEnd);
    return () => {
      textRef.current.removeEventListener('touchmove', handleTouchMove);
      textRef.current.removeEventListener('touchend', handleTouchEnd);
      textRef.current.removeEventListener('touchcancel', handleTouchEnd);
    };
  }, [isDragging, handleTouchMove, handleTouchEnd]);

  React.useEffect(() => {
    textRef.current.addEventListener('touchstart', handleTouchStart, { passive: false });
  }, []);

  return (
    <text
      fontSize={fontSize ?? '50px'}
      style={{ ...textStyle, zIndex: isDragging ? 4 : 1, cursor: 'move' }}
      ref={textRef}
      x={position.x}
      y={position.y}
      onMouseDown={handleMouseDown}
      // onTouchStart={handleTouchStart}
      dominantBaseline="middle"
      textAnchor="middle"
      // onMouseUp={handleMouseUp}
      // onMouseMove={handleMouseMove}
    >
      {text}
    </text>
  );
}

const FileEditorDropzone = forwardRef(({ onUpload, buttonText, adviceText, showFileName, maxSizeMb }, customRef) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileDataUrl, setFileDataUrl] = useState(null);
  const [isError, setIsError] = useState(false);
  const [filename, setFilename] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [addText, setAddText] = useState(false);
  const [topText, setTopText] = useState('Top Text');
  const [bottomText, setBottomText] = useState('Bottom Text');

  const [imageRef, setImageRef] = useState(null);
  const [svgRef, setSvgRef] = useState(null);

  const [debugLogs, setDebugLogs] = useState('');

  const NFT_MEME_SIZE = 309;

  const getSvgXRatio = () => {
    return svgRef?.current?.width ?? NFT_MEME_SIZE / 600;
  };

  const getSvgYRatio = () => {
    return svgRef?.current?.height ?? NFT_MEME_SIZE / 600;
  };

  const fontSizeInit = Math.round((getSvgXRatio() * 100) / 5) * 5;

  const [isTopFontSizeError, setIsTopFontSizeError] = useState(false);
  const [isBottomFontSizeError, setIsBottomFontSizeError] = useState(false);
  const [topFontSize, setTopFontSize] = useState(fontSizeInit);
  const [bottomFontSize, setBottomFontSize] = useState(fontSizeInit);
  const [touchmoveDisabled, setTouchmoveDisabled] = useState(false);

  const canvasRef = useRef();
  const canvasThumbnailRef = useRef();

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    setSelectedFile(file);

    if (!isSupportedFileType(file.type)) {
      toast.error(`File type ${file.type} unsupported.`);

      return;
    }

    if (maxSizeMb) {
      const fileSizeMb = convertKBtoMB(file.size);

      if (fileSizeMb > maxSizeMb) {
        toast.error('Max file size exceeded.');

        return;
      }
    }

    setIsLoading(true);
    setIsError(false);

    setFilename(file.name);

    const reader = new FileReader();

    reader.onabort = () => {
      setIsLoading(false);
    };
    reader.onerror = () => {
      console.log('reader onerror');
      setIsLoading(false);
      setIsError(true);
    };
    reader.onload = () => {
      setFileDataUrl(reader.result);
    };

    reader.readAsDataURL(file);
  }, []);

  const handleImageChange = async () => {
    await onImageLoad();
    debounce();
  };

  useEffect(async () => {
    if (selectedFile && svgRef) {
      /* setTimeout( () => {
        console.log("image => onImageLoad", selectedFile)
        //onImageLoad().then(()=>console.log("onImageLoad done"));
        //setIsLoading(false);
      }, 500) */
      console.log('handleImageChange useEffect');
      await handleImageChange();
      // setIsLoading(false);
    }
  }, [selectedFile, svgRef]);

  /*
  useEffect(async () => {
    if (!touchmoveDisabled) {
      window.removeEventListener('touchmove', (e) => preventTouch(e, touchmoveDisabled), { passive: false });
      setDebugLogs(`${debugLogs} remove2 touchmove,`);
      return;
    }

    window.addEventListener('touchmove', (e) => preventTouch(e, touchmoveDisabled), { passive: false });
    setDebugLogs(`${debugLogs} new touchmove,`);

    return () => {
      window.removeEventListener('touchmove', (e) => preventTouch(e, touchmoveDisabled), { passive: false });
      window.ontouchmove = null;
      setDebugLogs(`${debugLogs} remove touchmove,`);
    };
  }, [touchmoveDisabled]);
  */

  console.log('touchmoveDisabled', touchmoveDisabled);

  const preventTouch = useCallback(
    (event) => {
      console.log('preventTouch', touchmoveDisabled);
      if (touchmoveDisabled) {
        console.log(touchmoveDisabled, '4');
        event.preventDefault();
        return false;
      }
    },
    [touchmoveDisabled]
  );

  // useEffect(async () => {
  //   if (!touchmoveDisabled) {
  //     console.log('remove0 touchmove');
  //     window.removeEventListener('touchmove', preventTouch, false);
  //     window.removeEventListener('touchmove', preventTouch, true);
  //   } else {
  //     console.log('new touchmove');
  //     window.addEventListener('touchmove', preventTouch, { passive: false });
  //     // setDebugLogs(`${debugLogs} new touchmove,`);
  //   }
  //   return () => {
  //     console.log('remove2 touchmove');
  //     window.removeEventListener('touchmove', preventTouch, false);
  //     window.removeEventListener('touchmove', preventTouch, true);
  //   };
  // }, [touchmoveDisabled, preventTouch]);

  useEffect(async () => {
    window.addEventListener('touchmove', preventTouch, { passive: false });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1 });
  const { ref, ...dropzoneProps } = getRootProps();

  const getSquareDimensions = (target) => {
    let sx;
    let sy;
    let sw;
    let sh;

    let width = target?.naturalWidth || target?.videoWidth;
    let height = target?.naturalHeight || target?.videoHeight;

    if (!width || !height) {
      const svgSize = target.getBoundingClientRect();
      width = svgSize.width;
      height = svgSize.height;
    }

    // console.log("target", target)
    // console.log("width", width, "height", height)

    if (width > height) {
      sx = (width - height) / 2;
      sy = 0;
      sw = height;
      sh = height;
    } else {
      sx = 0;
      sy = (height - width) / 2;
      sh = width;
      sw = width;
    }

    // console.log("sx", sx, "sy", sy, "sh", sh, "sw", sw)

    return { sx, sy, sw, sh };
  };

  const getCroppedToSquareImage = (image) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const { sx, sy, sw, sh } = getSquareDimensions(image);

    const sideSize = Math.max(1000, sw, sh);

    canvas.width = sw;
    canvas.height = sh;

    // svg
    console.log('canvas image', image, selectedFile.type);
    const svgData = new XMLSerializer().serializeToString(image);
    // console.log("svgData", svgData)
    const img = document.createElement('img');
    img.setAttribute('src', `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgData)))}`);
    // console.log("img", img)
    // ctx.drawImage(img, sx, sy, sw, sh, 0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0, sideSize, sideSize, 0, 0, sideSize, sideSize);

    // image
    // ctx.drawImage(image, sx, sy, sw, sh, 0, 0, canvas.width, canvas.height);

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, selectedFile.type);
    });
  };

  const getCroppedToSquareThumbnail = async (target, canvas, minSize, maxSize, type, quality) => {
    // console.log('generating canvas', type, target);
    // const canvasThumbnail = canvasThumbnailRef.current;
    const canvasThumbnail = canvas.current;
    const ctxThumbnail = canvasThumbnail.getContext('2d', { preserveDrawingBuffer: false });

    const { sx, sy, sw, sh } = getSquareDimensions(target);

    // const width = target?.naturalWidth || target?.videoWidth || target?.clientWidth;
    // const height = target?.naturalHeight || target?.videoHeight || target?.clientHeight;

    let thumbnailSideSize;
    if (minSize) {
      thumbnailSideSize = minSize;
    } else if (maxSize) {
      thumbnailSideSize = Math.max(maxSize, sw, sh);
    }

    // let thumbnailSideSize = Math.min(400, sw, sh);

    canvasThumbnail.width = thumbnailSideSize;
    canvasThumbnail.height = thumbnailSideSize;

    const thumbnailSvg = target.cloneNode(true);

    const textElements = thumbnailSvg.querySelectorAll('text');
    textElements.forEach((textElement) => {
      let x;
      let y;
      const xString = textElement.getAttribute('x');
      if (xString.endsWith('%')) {
        // may be wrong, stopped using it
        x = (sw * parseFloat(xString.replace('%', ''))) / 100;
      } else {
        x = parseFloat(xString);
      }

      const yString = textElement.getAttribute('y');
      if (yString.endsWith('%')) {
        y = (sh * parseFloat(yString.replace('%', ''))) / 100;
      } else {
        y = parseFloat(yString);
      }

      const fontSize = parseFloat(textElement.getAttribute('font-size').replace('px', ''));

      /*
      const newX = x * (thumbnailSideSize / sw);
      const newY = y * (thumbnailSideSize / sh);
      const newFontSize = fontSize * (thumbnailSideSize / sw);

       */

      /* const newX = x * (thumbnailSideSize / sw);
      const newY = y * (thumbnailSideSize / sh);
      const newFontSize = fontSize * (thumbnailSideSize / sw); */

      const newX = x;
      const newY = y;
      const newFontSize = fontSize;

      console.log(
        'txt',
        minSize ? 'thumb' : 'big',
        sw,
        thumbnailSideSize,
        'x',
        x,
        newX,
        'y',
        y,
        newY,
        'f',
        fontSize,
        newFontSize
      );

      textElement.setAttribute('x', newX);
      textElement.setAttribute('y', newY);
      textElement.setAttribute('font-size', newFontSize);
    });

    // svg
    // console.log("canvasThumbnail image", thumbnailSvg)
    const svgData = new XMLSerializer().serializeToString(thumbnailSvg);
    // console.log("svgData", svgData)
    const img = document.createElement('img');
    img.setAttribute('src', `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgData)))}`);
    // console.log("img", img)
    // console.log('sw -> real', sw, sh, thumbnailSideSize);
    // ctxThumbnail.drawImage(img, 0, 0, sw, sh, 0, 0, thumbnailSideSize, thumbnailSideSize);
    ctxThumbnail.drawImage(img, 0, 0, thumbnailSideSize, thumbnailSideSize);

    // console.log('canvas', canvas.current, canvas);

    // image
    // ctxThumbnail.drawImage(target, sx, sy, sw, sh, 0, 0, canvasThumbnail.width, canvasThumbnail.height);

    return new Promise((resolve) => {
      canvasThumbnail.toBlob(
        (blob) => {
          resolve(blob);
        },
        type,
        quality
      );
    });
  };

  const convertSvgToImage = () => {
    console.log('svgRef', svgRef);
    // return svgRef;
    const svg = svgRef;
    const svgData = new XMLSerializer().serializeToString(svg);

    console.log('svgData', svgData);
    // return svgData;

    console.log('image', `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgData)))}`);

    return `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgData)))}`;
    /*
    download

    const canvas = document.createElement("canvas");
    canvas.setAttribute("id", "canvas");
    const svgSize = svg.getBoundingClientRect();
    canvas.width = svgSize.width;
    canvas.height = svgSize.height;

    const img = document.createElement("img");
    img.setAttribute("src", "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData))));
    img.onload = function() {
      canvas.getContext("2d").drawImage(img, 0, 0);
      const canvasdata = canvas.toDataURL("image/png");
      const a = document.createElement("a");
      a.download = "meme.png";
      a.href = canvasdata;
      document.body.appendChild(a);
      a.click();
    }; */
  };

  let isImageLoading = false; // Флаг, указывающий, выполняется ли загрузка изображения
  let timeoutId = null; // Идентификатор таймаута для debounce

  const onImageLoad = async () => {
    // console.log("onImageLoad,", onUpload, event)
    // const image = event.target;

    if (isImageLoading || timeoutId) {
      console.log('load exit', isImageLoading, timeoutId);
      return;
    }

    isImageLoading = true;

    if (onUpload) {
      onUpload({
        file: null,
        thumbnailFile: null,
      });

      let file;
      let thumbnailFile;

      /*
      while (true){
        if ( isFileTypeAnimatedImage(selectedFile.type)){
          file = selectedFile;
          canvasRef.current = file
          break;
        }
        else {
          file = await getCroppedToSquareThumbnail(svgRef, canvasRef, 0, 1000, selectedFile.type)

          if (file.size > 6711) {
            break;
          }
        }
        console.log("file rebuilding")
      }

      while (true){
        thumbnailFile =  await getCroppedToSquareThumbnail(svgRef, canvasThumbnailRef, 400, 0, 'image/jpeg', '0.8');
        if (thumbnailFile.size > 1695) {
          break;
        }
        console.log("thumb rebuilding")
      } */

      try {
        while (true) {
          thumbnailFile = await getCroppedToSquareThumbnail(svgRef, canvasThumbnailRef, 400, 0, 'image/jpeg', '0.8');
          if (thumbnailFile.size > 2000) {
            break;
          }
        }

        while (true) {
          if (isFileTypeAnimatedImage(selectedFile.type)) {
            file = selectedFile;
            canvasRef.current = file;
          } else {
            // TODO await is not supported here
            file = await getCroppedToSquareThumbnail(svgRef, canvasRef, 0, 1000, selectedFile.type, 1);
          }

          if (file.size > thumbnailFile.size) {
            break;
          }
          console.log('file rebuilding');
        }

        // console.log('file', file, 'thumbnailFile', thumbnailFile);

        onUpload({
          file,
          thumbnailFile,
        });
      } catch (error) {
        // Обработка ошибок при загрузке изображения
        console.error('Error while loading image:', error);
      } finally {
        // Сбрасываем флаг загрузки
        isImageLoading = false;
      }
    }

    setIsLoading(false);
    /*

      console.log("file", file, "thumbnailFile", thumbnailFile)

      if (thumbnailFile.size > 1695 && file.size > 6711) {
        onUpload({
          file,
          thumbnailFile,
        });
      }
      else {
        console.log("repeat");
        onImageLoad()
      }
    }

     */
  };

  const debounce = () => {
    // Если уже запланирован вызов функции, просто игнорируем новый вызов
    if (timeoutId) {
      return;
    }

    if (timeoutId) {
      clearInterval(timeoutId);
    }

    // Устанавливаем таймаут для вызова onImageLoad после некоторой задержки
    timeoutId = setInterval(async () => {
      if (!isImageLoading) {
        // console.log('debounce', isImageLoading, timeoutId);
        clearInterval(timeoutId);
        timeoutId = null; // Сбрасываем таймаут
        await onImageLoad(); // Вызываем onImageLoad для захвата последнего действия
      }
    }, 500); // Интервал debounce в миллисекундах (в данном случае 500 мс)
  };

  const getVideoCroppedToSquareThumbnail = (target) => {
    const canvasThumbnail = canvasThumbnailRef.current;
    const ctxThumbnail = canvasThumbnail.getContext('2d');

    const { sx, sy, sw, sh } = getSquareDimensions(target);

    const width = target.naturalWidth || target.videoWidth;
    const height = target.naturalHeight || target.videoHeight;

    const thumbnailSideSize = Math.min(400, width, height);

    canvasThumbnail.width = thumbnailSideSize;
    canvasThumbnail.height = thumbnailSideSize;

    ctxThumbnail.drawImage(target, sx, sy, sw, sh, 0, 0, canvasThumbnail.width, canvasThumbnail.height);

    return new Promise((resolve) => {
      canvasThumbnail.toBlob(
        (blob) => {
          resolve(blob);
        },
        'image/jpeg',
        0.7
      );
    });
  };

  const onVideoLoad = async (event) => {
    const video = event.target;

    if (onUpload) {
      onUpload({
        file: selectedFile,
        thumbnailFile: await getVideoCroppedToSquareThumbnail(video),
      });
    }

    setIsLoading(false);
  };

  const imgStyle = {
    /* width: '100%',
    height: '100%', */
    textAlign: 'center',
    /* position: 'absolute', */
    borderRadius: 'var(--radius-default)',
  };

  const textStyle = {
    fontFamily: 'Impact',
    letterSpacing: '1.4',
    textTransform: 'uppercase',
    fill: '#FFF',
    stroke: '#000',
    paintOrder: 'stroke',
    strokeWidth: '4px',
    strokeLinecap: 'butt',
    strokeLinejoin: 'miter',
    fontWeight: 800,
    userSelect: 'none',
    webkitUserSelect: 'none',
    mozUserSelect: 'none',
    msUserSelect: 'none',
  };

  const hideTexts = async () => {
    setAddText(false);
    handleImageChange();
  };

  const addTexts = async () => {
    setAddText(true);
    handleImageChange();
  };

  return (
    <StyledContainer>
      {fileDataUrl ? (
        <div>
          {selectedFile?.type && isFileTypeImage(selectedFile.type) && (
            <div>
              {!isFileTypeAnimatedImage(selectedFile.type) && (
                <div>
                  {addText && (
                    <Button className={'add-text-button'} onClick={hideTexts}>
                      - Hide Texts
                    </Button>
                  )}
                  {!addText && (
                    <Button className={'add-text-button'} onClick={addTexts}>
                      + Add Texts
                    </Button>
                  )}
                  {addText && (
                    <div className="meme-form">
                      <div>
                        <MemeInputText
                          labelText="Top Text"
                          name="meme_top_text"
                          value={topText}
                          fontSize={topFontSize}
                          maxLength={APP.GEM_MEME_TEXT_MAX_LENGTH}
                          onChange={async (e) => {
                            setTopText(e.currentTarget.value);
                            handleImageChange();
                          }}
                          onSetFontSize={async (e, isError) => {
                            setIsTopFontSizeError(isError);
                            setTopFontSize(e.currentTarget.value);
                            handleImageChange();
                          }}
                        />
                      </div>
                      <div>
                        <MemeInputText
                          labelText="Bottom Text"
                          name="meme_bottom_text"
                          value={bottomText}
                          fontSize={bottomFontSize}
                          maxLength={APP.GEM_MEME_TEXT_MAX_LENGTH}
                          onChange={async (e) => {
                            setBottomText(e.currentTarget.value);
                            handleImageChange();
                          }}
                          onSetFontSize={async (e, isError) => {
                            setIsBottomFontSizeError(isError);
                            setBottomFontSize(e.currentTarget.value);
                            handleImageChange();
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="image-container" style={{ textAlign: 'center' }}>
                {/*
                <img src={fileDataUrl} alt="selected file" onLoad={onImageLoad} />
                */}

                <svg
                  ref={(el) => setSvgRef(el)}
                  style={{ ...imgStyle }}
                  width={`${NFT_MEME_SIZE}px`}
                  height={`${NFT_MEME_SIZE}px`}
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnshlink="http://www.w3.org/1999/xlink"
                  onMouseOut={handleImageChange}
                  preserveAspectRatio="xMaxYMax slice"
                  /* preserveAspectRatio="xMaxYMax" */
                >
                  {/*
                  export https://github.com/AvanthikaMeenakshi/ReactMemeMaker/blob/33a8952d04f7d6f42be0c491c572cede89eacac8/src/Components/MainPage/index.js#L224
                  draggable text https://stackoverflow.com/a/75740690
                   */}

                  <image
                    style={{ ...imgStyle }}
                    width={`${NFT_MEME_SIZE}px`}
                    height={`${NFT_MEME_SIZE}px`}
                    ref={(el) => setImageRef(el)}
                    xlinkHref={fileDataUrl}
                    onLoad={() => {
                      //   setTimeout(async () => {
                      //   console.log("image => onImageLoad")
                      //   await onImageLoad();
                      //   setIsLoading(false);
                      // }, 1000)
                    }}
                    preserveAspectRatio="xMidYMid slice"
                  />

                  {addText && (
                    <>
                      <DraggableText
                        x={getSvgXRatio() * 310}
                        y={getSvgYRatio() * 95}
                        text={topText}
                        textStyle={textStyle}
                        fontSize={topFontSize}
                        imageRef={imageRef}
                        onDragStop={handleImageChange}
                        onTouchStart={() => {
                          setTouchmoveDisabled(true);
                        }}
                        onTouchEnd={() => {
                          setTouchmoveDisabled(false);
                        }}
                      />
                      <DraggableText
                        x={getSvgXRatio() * 300}
                        y={getSvgYRatio() * 525}
                        text={bottomText}
                        textStyle={textStyle}
                        fontSize={bottomFontSize}
                        imageRef={imageRef}
                        onDragStop={handleImageChange}
                        onTouchStart={() => {
                          setTouchmoveDisabled(true);
                        }}
                        onTouchEnd={() => {
                          setTouchmoveDisabled(false);
                        }}
                      />
                    </>
                  )}

                  {/*
                <text
                    style={{...textStyle, zIndex: isTopDragging ? 4 : 1 }}
                    x={topX}
                    y={topY}
                    dominantBaseline="middle"
                    textAnchor="middle"
                    onMouseDown={event => handleMouseDown(event, 'top')}
                    onMouseUp={event => handleMouseUp(event, 'top')}
                >
                  {topText}
                </text>

                <text
                    style={{...textStyle, zIndex: isBottomDragging ? 4 : 1 }}
                    x={bottomX}
                    y={bottomY}
                    dominantBaseline="middle"
                    textAnchor="middle"
                    onMouseDown={event => handleMouseDown(event, 'bottom')}
                    onMouseUp={event => handleMouseUp(event, 'bottom')}
                >
                  {bottomText}
                </text>
                */}
                </svg>
              </div>
            </div>
          )}
          {selectedFile?.type && isFileTypeVideo(selectedFile.type) && (
            <div className="image-container">
              <video src={fileDataUrl} autoPlay muted loop onLoadedData={onVideoLoad} />
            </div>
          )}
          <div style={{ display: 'none' }}>
            <canvas key={`canvas_orig_1`} ref={canvasRef} />
            <canvas key={`canvas_orig_2`} className="canvas-thumbnail" ref={canvasThumbnailRef} />
          </div>
        </div>
      ) : (
        <div className="input-container" ref={customRef || ref} {...dropzoneProps}>
          {isDragActive ? 'Drop files here.' : <Button isSecondary>{buttonText}</Button>}
          <input
            {...getInputProps({
              multiple: false,
              accept: 'image/*,video/*',
            })}
          />
        </div>
      )}
      {filename && showFileName && <p className="extra-text">{filename}</p>}
      {isLoading && <p className="extra-text">Loading...</p>}
      {isError && <p className="extra-text">Something is wrong. Try again.</p>}
      {adviceText && <p className="advice extra-text">{adviceText}</p>}
    </StyledContainer>
  );
});

FileEditorDropzone.propTypes = {
  onUpload: PropTypes.func,
  buttonText: PropTypes.string.isRequired,
  adviceText: PropTypes.string,
  showFileName: PropTypes.bool,
  maxSizeMb: PropTypes.number,
};

FileEditorDropzone.defaultProps = {
  showFileName: true,
};

export default FileEditorDropzone;
