import axios from 'axios';

import { APP } from '~/constants';

const ipfsReadHref = 'https://ipfs.near.social/ipfs/';
const ipfsWriteHref = 'https://ipfs.near.social/add';

const readBlobAsDataUrl = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });

const readBlobAsText = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(blob);
  });

export const getIpfsPath = (hash) => {
  return `${ipfsReadHref}/${hash}`;
};

export const getFileData = async (hash) => {
  let response;

  try {
    response = await fetch(`${ipfsReadHref}/${hash}`);
  } catch (e) {
    console.error(`getFileData ${ipfsReadHref}/${hash} error`, e);

    return null;
  }

  try {
    const blob = await response.blob();

    if (blob.type === 'application/json') {
      const text = await readBlobAsText(blob);

      return JSON.parse(text)?.file || null;
    }

    return await readBlobAsDataUrl(blob);
  } catch (e) {
    console.error(e);
  }

  return null;
};

export const uploadFile = async (file) => {
  const response = await axios.post(ipfsWriteHref, file, {
    maxBodyLength: 'Infinity', // this is needed to prevent axios from erroring out with large files
  });

  return response.data.cid;
};
