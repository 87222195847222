import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link, useRouteMatch } from 'react-router-dom';

import { NearContext } from '~/contexts';

import { APP } from '~/constants';
import { DiamondIcon } from '~/components/common/icons';

const StyledContainer = styled('div')`
  height: 52px;
  line-height: 52px;
  font-size: 20px;
  font-weight: 300;
  cursor: default;
  user-select: none;
  font-family: var(--font-secondary);

  a {
    color: var(--lavendar);
  }

  @media (min-width: 767px) {
    font-size: 30px;
  }

  .diamond {
    display: inline-block;
    margin-left: 5px;
  }
`;

const Left = () => {
  const isHomePage = useRouteMatch('/')?.isExact;
  const isProfilePage = useRouteMatch('/profile')?.isExact;
  const isGemPage = useRouteMatch('/gem');
  const isGemOriginalPage = useRouteMatch('/gem-original');
  const isNotEnoughBalancePage = useRouteMatch('/not-enough-balance');

  const { user } = useContext(NearContext);

  if (isGemPage || isGemOriginalPage || isNotEnoughBalancePage) {
    return null;
  }

  let toRender;

  if (isHomePage) {
    toRender = (
      <>
        {APP.NAME}
        <div className="diamond">
          <DiamondIcon style={{ verticalAlign: 'middle' }} />
        </div>
      </>
    );
  } else if (isProfilePage && user?.accountId) {
    toRender = user.accountId;
  } else {
    toRender = (
      <Link to="/">
        {APP.NAME}{' '}
        <div className="diamond">
          <DiamondIcon />
        </div>
      </Link>
    );
  }

  return <StyledContainer>{toRender}</StyledContainer>;
};

export default Left;
