import PropTypes from 'prop-types';

const StylesType = PropTypes.shape({
  alignContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alignItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alignSelf: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  all: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animationDelay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animationDirection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animationDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animationFillMode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animationIterationCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animationName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animationPlayState: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animationTimingFunction: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backfaceVisibility: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundAttachment: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundBlendMode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundClip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundOrigin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundRepeat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  blockSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBlockEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBlockEndColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBlockEndStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBlockEndWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBlockStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBlockStartColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBlockStartStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBlockStartWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBottomColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBottomLeftRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBottomRightRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBottomStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderBottomWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderCollapse: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderImage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderImageOutset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderImageRepeat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderImageSlice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderImageSource: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderImageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderInlineEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderInlineEndColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderInlineEndStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderInlineEndWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderInlineStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderInlineStartColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderInlineStartStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderInlineStartWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderLeftColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderLeftStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderLeftWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRightColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRightStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRightWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderTopColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderTopLeftRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderTopRightRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderTopStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderTopWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  boxDecorationBreak: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  boxShadow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  boxSizing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  breakAfter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  breakBefore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  breakInside: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  captionSide: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  caretColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ch: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clipPath: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnFill: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnRule: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnRuleColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnRuleStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnRuleWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnSpan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  counterIncrement: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  counterReset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cursor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  deg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  direction: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dpcm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dpi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dppx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  em: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  emptyCells: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexBasis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexDirection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexFlow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexGrow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexShrink: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexWrap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  float: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  font: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontFamily: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontFeatureSettings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontKerning: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontLanguageOverride: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSizeAdjust: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontStretch: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSynthesis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontVariant: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontVariantAlternates: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontVariantCaps: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontVariantEastAsian: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontVariantLigatures: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontVariantNumeric: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontVariantPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  grad: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  grid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridArea: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridAutoColumns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridAutoFlow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridAutoRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridColumn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridColumnEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridColumnGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridColumnStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridRowEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridRowGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridRowStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridTemplate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridTemplateAreas: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridTemplateColumns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridTemplateRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hangingPunctuation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hyphens: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hz: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageOrientation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageRendering: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageResolution: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  in: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inherit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initial: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inlineSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isolation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  justifyContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  khz: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  letterSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lineBreak: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  listStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  listStyleImage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  listStylePosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  listStyleType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBlockEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBlockStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginInlineEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginInlineStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mask: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maskClip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maskComposite: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maskImage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maskMode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maskOrigin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maskPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maskRepeat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maskSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maskType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minBlockSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minInlineSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mixBlendMode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ms: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  objectFit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  objectPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offsetBlockEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offsetBlockStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offsetInlineEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offsetInlineStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  opacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orphans: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  outline: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  outlineColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  outlineOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  outlineStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  outlineWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overflow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overflowWrap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overflowX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overflowY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingBlockEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingBlockStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingInlineEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingInlineStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageBreakAfter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageBreakBefore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageBreakInside: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  perspective: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  perspectiveOrigin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pointerEvents: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  q: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  quotes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rad: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  revert: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rubyAlign: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rubyMerge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rubyPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  s: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scrollBehavior: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scrollSnapCoordinate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scrollSnapDestination: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scrollSnapType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shapeImageThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shapeMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shapeOutside: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tableLayout: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textAlign: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textAlignLast: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textCombineUpright: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textDecoration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textDecorationColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textDecorationLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textDecorationStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textEmphasis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textEmphasisColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textEmphasisPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textEmphasisStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textIndent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textJustify: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textOrientation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textOverflow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textRendering: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textShadow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textTransform: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textUnderlinePosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  touchAction: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transform: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transformBox: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transformOrigin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transformStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transitionDelay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transitionDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transitionProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transitionTimingFunction: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  turn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unicodeBidi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  verticalAlign: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visibility: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vmax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vmin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vw: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  whiteSpace: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  widows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  willChange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wordBreak: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wordSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wordWrap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  writingMode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overflowScrolling: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userSelect: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export default StylesType;
