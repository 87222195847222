const APP = {
  NAME: 'HOT SHOT',

  DEFAULT_ROYALTY: 5,
  MIN_CREATOR_ROYALTY: 0,
  MIN_COLLABORATOR_ROYALTY: 0.01,
  MAX_ROYALTY: 80,

  MIN_NEARS: 0,
  MIN_NEARS_TO_MINT: 0.05,

  NEARS_TO_NEXT_BID: '1',

  MAX_COLLABORATORS: 6,

  GEM_DESCRIPTION_MAX_LENGTH: 160,
  GEM_TITLE_MAX_LENGTH: 100,
  GEM_MEME_TEXT_MAX_LENGTH: 50,
  GEM_MEME_TEXT_MIN_FONT_SIZE: 10,
  GEM_MEME_TEXT_MAX_FONT_SIZE: 100,
  GEM_MAX_SIZE_MB: 10,

  AN_MEDIA_MAX_SIZE_BEFORE_THUMBNAIL_MB: 1,

  MAX_ITEMS_PER_PAGE_PROFILE: 18,
  MAX_ITEMS_PER_PAGE_HOME: 18,

  PREPAID_GAS_LIMIT: '200000000000000',
  PREPAID_GAS_LIMIT_HALF: '100000000000000',
  DEPOSIT_DEFAULT: '15000000000000000000000',

  HASH_SOURCE: '',
  PINATA_API_KEY: 'cc68f8fd06191f832feb',
  PINATA_API_SECRET: 'dbc7f2309bd4a234bf2804097376ecba60a1a15838ea5485014b6f21e9dab3bc',

  USE_STORAGE_FEES: false,
};

export default APP;
