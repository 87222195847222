import React from 'react';
import styled from 'styled-components';

import InputSignAside from './InputSignAside';

import { APP } from '~/constants';

const StyledContainer = styled('div')`
  .input {
    width: 80px;
  }

  .aside {
    color: #ffffff;
  }
`;

const InputFontSize = ({ ...rest }) => (
  <StyledContainer>
    <InputSignAside
      type="number"
      className={"number-input"}
      sign=""
      min={APP.GEM_MEME_TEXT_MIN_FONT_SIZE}
      max={APP.GEM_MEME_TEXT_MAX_FONT_SIZE}
      marginBottom="0px"
      {...rest}
    />
  </StyledContainer>
);

export default InputFontSize;
