import React from 'react';
import styled from 'styled-components';

import { Button } from './common/buttons';
import { HeadingText } from './common/typography';
import HeadingSmallText from './common/typography/HeadingSmallText';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 28px;
  text-align: center;
`;

const ErrorFallback = (err) => (
  <Container>
    <HeadingSmallText>
      Sorry <br /> There was an unexpected error
    </HeadingSmallText>
    <Button isPrimary>
      <a href="/">Back home</a>
    </Button>
    <pre>{JSON.stringify(err)}</pre>
  </Container>
);

export default ErrorFallback;
