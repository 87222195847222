import Input from './Input';
import InputNear from './InputNear';
import InputRoyalty from './InputRoyalty';
import InputSign from './InputSign';
import InputSignAside from './InputSignAside';
import Textarea from './Textarea';
import MemeInputText from "./MemeInputText";
import InputFontSize from "./InputFontSize"

export { Input, InputNear, InputRoyalty, InputSignAside, Textarea, InputSign, MemeInputText, InputFontSize };
