import { utils, connect, Contract, keyStores, WalletConnection } from 'near-api-js';
import uuid4 from 'uuid4';
import { HereWallet } from '@here-wallet/core';
import getConfig from './config';

import { NftMethods, MarketMethods } from './constants/contractMethods';
import { APP } from './constants';

const nearConfig = getConfig(process.env.NODE_ENV || 'production');

export function isTestnet() {
  return process.env.NODE_ENV === 'testnet';
}

export function getReferral() {
  const storedReferralId = localStorage.getItem('referralId');
  return !storedReferralId ? null : storedReferralId;
}

export const getMarketContractName = (nftContractName) => `market.${nftContractName}`;

// Initialize contract & set global variables
export async function initContractsOld() {
  // Initialize connection to the NEAR testnet
  const near = await connect({ deps: { keyStore: new keyStores.BrowserLocalStorageKeyStore() }, ...nearConfig });

  // Initializing Wallet based Account. It can work with NEAR testnet wallet that
  // is hosted at https://wallet.testnet.near.org
  const walletConnection = new WalletConnection(near);

  // Load in account data
  let currentUser;
  if (walletConnection.getAccountId()) {
    currentUser = {
      accountId: walletConnection.getAccountId(),
      balance: (await walletConnection.account().state()).amount,
    };
  }

  // Initializing our contract APIs by contract name and configuration
  const nftContract = await new Contract(walletConnection.account(), nearConfig.contractName, {
    // View methods are read only. They don't modify the state, but usually return some value.
    viewMethods: [...NftMethods.viewMethods],
    // Change methods can modify the state. But you don't receive the returned value when called.
    changeMethods: [...NftMethods.changeMethods],
    // Sender is the account ID to initialize transactions.
    sender: walletConnection.getAccountId(),
  });

  // Initializing our contract APIs by contract name and configuration
  const marketContract = await new Contract(
    walletConnection.account(),
    getMarketContractName(nearConfig.contractName),
    {
      // View methods are read only. They don't modify the state, but usually return some value.
      viewMethods: [...MarketMethods.viewMethods],
      // Change methods can modify the state. But you don't receive the returned value when called.
      changeMethods: [...MarketMethods.changeMethods],
      // Sender is the account ID to initialize transactions.
      sender: walletConnection.getAccountId(),
    }
  );

  return {
    nftContract,
    marketContract,
    currentUser,
    nearConfig,
    walletConnection,
    near,
  };
}

export const getHereWallet = async () => {
  return HereWallet.connect({
    // botId: 'near_social_bot/app',
    botId: 'HotShotNFTbot/app',
    walletId: 'herewalletbot/app',
  });
};

export async function initContracts() {
  // Initialize connection to the NEAR testnet
  const near = await connect({ deps: { keyStore: new keyStores.BrowserLocalStorageKeyStore() }, ...nearConfig });
  console.log('initContracts', near);

  // Initializing Wallet based Account. It can work with NEAR testnet wallet that
  // is hosted at https://wallet.testnet.near.org
  const walletConnection = new WalletConnection(near);

  // Load in account data

  let currentUser;

  if (isTestnet()) {
    if (walletConnection.getAccountId()) {
      currentUser = {
        accountId: walletConnection.getAccountId(),
        balance: (await walletConnection.account().state()).amount,
      };
    }
  } else {
    const here = await getHereWallet();
    if (await here.isSignedIn()) {
      currentUser = {
        accountId: await here.getAccountId(),
        balance: (await here.getAvailableBalance()).toString(),
      };
    }
  }

  // Initializing our contract APIs by contract name and configuration
  const nftContract = await new Contract(walletConnection.account(), nearConfig.contractName, {
    // View methods are read only. They don't modify the state, but usually return some value.
    viewMethods: [...NftMethods.viewMethods],
    // Change methods can modify the state. But you don't receive the returned value when called.
    changeMethods: [...NftMethods.changeMethods],
    // Sender is the account ID to initialize transactions.
    sender: walletConnection.getAccountId(),
  });

  // Initializing our contract APIs by contract name and configuration
  const marketContract = await new Contract(
    walletConnection.account(),
    getMarketContractName(nearConfig.contractName),
    {
      // View methods are read only. They don't modify the state, but usually return some value.
      viewMethods: [...MarketMethods.viewMethods],
      // Change methods can modify the state. But you don't receive the returned value when called.
      changeMethods: [...MarketMethods.changeMethods],
      // Sender is the account ID to initialize transactions.
      sender: walletConnection.getAccountId(),
    }
  );

  return {
    nftContract,
    marketContract,
    currentUser,
    nearConfig,
    walletConnection,
    near,
  };
}

export const disconnectHereWallet = async () => {
  const here = await getHereWallet();
  await here.signOut();
};

export const connectHereWallet = async () => {
  const here = await getHereWallet();

  if ((await here.isSignedIn()) === false) {
    await here.signIn({
      contractId: nearConfig.contractName,
      callbackUrl: '/#/',
    });
  } else {
    // TODO
    console.log('Already signed in');
  }

  /*
  try {
    console.log('here', here, await here.isSignedIn(), await here.getAccountId());
    // return;
  } catch (ex) {
    console.log(ex);

    const account = await here.signIn({
      contractId: 'social.near',
    });
  }

  const account = await here.getAccountId();

  console.log('here', here, await here.getAccountId(), account);

  const result = await here.signAndSendTransactions({
    callbackUrl: '/success',
    transactions: [
      {
        receiverId: 'social.near',
        actions: [
          {
            type: 'FunctionCall',
            params: {
              methodName: 'set',
              args: { data: { [account]: { profile: { hereUser: 'yes' } } } },
              gas: '30000000000000',
              deposit: '1',
            },
          },
        ],
      },
    ],
  });

  console.log(result);
   */

  /*
  const id = uuid4();
  const data = utils.serialize.base_encode(
    JSON.stringify({
      ...request,
      callbackUrl: 'https://nearwebapp.nearspace.info/',
      id,
    })
  );

  const WEB_APP_URL = `https://t.me/herewalletbot/app?startapp=${data}`;

  window.Telegram.WebApp.openTelegramLink(WEB_APP_URL);
   */
};

export async function login() {
  // Allow the current app to make calls to the specified contract on the
  // user's behalf.
  // This works by creating a new access key for the user's account and storing
  // the private key in localStorage.

  if (isTestnet()) {
    window.walletConnection.requestSignIn(nearConfig.contractName, APP.NAME);
  } else {
    await connectHereWallet();
  }
}

export async function logout() {
  if (isTestnet()) {
    window.walletConnection.signOut();
  } else {
    await disconnectHereWallet();
  }
  // reload page
  window.location.replace(window.location.origin + window.location.pathname);
}
