import React from 'react';

export default function DiamondIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12px"
      height="20px"
      viewBox="0 0 12 20"
      fill="none"
      className="diamond-icon"
    >
      <g>
        <path d="M 1.316406 17.5 L 1.742188 20 L 10.253906 20 L 10.679688 17.5 Z M 1.316406 17.5 " fill="#F8DDFF" />
        <path
          d="M 10.964844 15.832031 L 11.957031 10 L 0.0429688 10 L 1.035156 15.832031 Z M 10.964844 15.832031 "
          fill="#F8DDFF"
        />
        <path
          d="M 9.683594 8.332031 C 10.582031 6.933594 10.414062 5.066406 9.167969 3.847656 L 7.859375 2.5625 C 7.136719 1.855469 6.773438 0.929688 6.773438 0 C 5.546875 1.203125 4.789062 2.859375 4.789062 4.691406 L 4.789062 5.769531 C 3.707031 5.769531 2.828125 4.90625 2.828125 3.847656 C 1.582031 5.0625 1.414062 6.933594 2.316406 8.332031 Z M 9.683594 8.332031 "
          fill="#F8DDFF"
        />
      </g>
    </svg>
  ); /*
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="diamond-icon"
    >
      <path
        d="M12.0973 0.75H2.92499C2.82075 0.75 2.71652 0.804634 2.66441 0.913902L0.05863 4.92951C-0.0195433 5.03878 -0.0195433 5.20268 0.05863 5.31195L7.25058 14.6271C7.38087 14.791 7.61539 14.791 7.74567 14.6271L14.9376 5.31195C15.0158 5.20268 15.0158 5.03878 14.9637 4.92951L12.3579 0.913902C12.2797 0.804634 12.2016 0.75 12.0973 0.75ZM11.5762 2.06122L13.2439 4.68366H11.4459L10.0909 2.06122H11.5762ZM6.3125 2.06122H8.68375L10.0388 4.68366H4.98355L6.3125 2.06122ZM3.42008 2.06122H4.90538L3.55037 4.68366H1.77844L3.42008 2.06122ZM2.2996 5.99488H3.62855L5.42653 10.3656L2.2996 5.99488ZM4.98355 5.99488H10.0127L7.51115 12.6602L4.98355 5.99488ZM9.59578 10.3656L11.3677 5.99488H12.6967L9.59578 10.3656Z"
        fill="#F8DDFF"
      />
    </svg>
  ); */
}
